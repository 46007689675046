import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { Declaration, TransactionTypes } from '@e-origin/shared';

import { Input } from '../../../../../../shared/components';
import { Autocomplete } from '../../../../../../shared/components/autocomplete/autocomplete.component';
import { selectDeclarant } from '../../../../../../shared/stores/declarantSlice';
import { retrieveCodes } from '../../../../../../shared/stores/settingsSlice';
import { FormContainer, FormRow, FormSection } from '../../../../../../styles/common';
import { IBeforeSendingFormData } from '../before-sending-batch-form-initializer';
import * as CommonStyle from '../before-sending-batch.style';

interface ITransportProps {
  form: FormikProps<IBeforeSendingFormData>;
  declaration: Declaration;
  disabled: boolean;
}

export const FinalizeCustomsTreatment: React.FC<ITransportProps> = ({ form, declaration, disabled }) => {
  const declarant = useSelector(selectDeclarant);
  const isExport = declaration?.generalInfo?.isExport;
  const paymentDetailsDisabled = declaration.customer !== form.values.customer || disabled;

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0}>
          <FormRow>
            <Autocomplete
              width={30}
              widthUnit="%"
              placeholder="Payment Method"
              fetchOptions={(search) => retrieveCodes('CL104', search, declarant.language)}
              onChange={(selectedOption) => form.setFieldValue('messageInfo.methodOfPayment', selectedOption?.value)}
              value={{
                value: form.values.messageInfo.methodOfPayment,
                label: form.values.messageInfo.methodOfPayment,
              }}
              disabled={disabled}
              compact
            />
            <Input
              name="messageInfo.deferredPayment"
              placeholder="Deferred payment "
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.messageInfo.deferredPayment}
              width={30}
              widthUnit="%"
              compact
              disabled={paymentDetailsDisabled}
            />
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="VAT Payment Method"
              fetchOptions={(search) => retrieveCodes('CL104', search, declarant.language)}
              onChange={(selectedOption) => form.setFieldValue('messageInfo.methodOfPaymentVAT', selectedOption?.value)}
              value={{
                value: form.values.messageInfo.methodOfPaymentVAT,
                label: form.values.messageInfo.methodOfPaymentVAT,
              }}
              disabled={disabled}
              compact
            />
          </FormRow>
        </FormSection>
        <FormSection verticalPadding={0}>
          <CommonStyle.SectionTitle>Total</CommonStyle.SectionTitle>
          <FormRow>
            <Input
              placeholder="Total Weight"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={declaration.globalInfo.totalWeight}
              width={20}
              widthUnit="%"
              compact
              disabled
            />
            <Input
              placeholder="Total amount invoiced"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={declaration.globalInfo.totalAmountInvoiced}
              width={20}
              widthUnit="%"
              compact
              disabled
            />
            <Input
              placeholder="Invoice currency"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={declaration.globalInfo.invoiceCurrency}
              width={20}
              widthUnit="%"
              compact
              disabled
            />
            <Input
              placeholder="Total PCS"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={declaration.globalInfo.totalPCS}
              width={20}
              widthUnit="%"
              compact
              disabled
            />
            <Input
              placeholder="Total CNTS"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={declaration.globalInfo.totalCNTS}
              width={20}
              widthUnit="%"
              compact
              disabled
            />
          </FormRow>
          {[TransactionTypes.B2B_HV, undefined].includes(declaration.transactionType) && (
            <FormRow>
              <Autocomplete
                width={20}
                widthUnit="%"
                placeholder="Package Type"
                fetchOptions={(search: string) => retrieveCodes('CL017', search, declarant.language)}
                onChange={(selectedOption) =>
                  form.setFieldValue('declarationGoods.packaging.typeOfPackages', selectedOption?.value)
                }
                value={{
                  value: form.values.declarationGoods.packaging.typeOfPackages,
                  label: form.values.declarationGoods.packaging.typeOfPackages,
                }}
                disabled={disabled}
                compact
              />
            </FormRow>
          )}
        </FormSection>
        {!isExport && declaration.transactionType !== TransactionTypes.B2B_MULTIPLE && (
          <>
            <FormSection verticalPadding={0}>
              <CommonStyle.SectionTitle>Charges import</CommonStyle.SectionTitle>
              <FormRow>
                <Input
                  placeholder="Delivery terms"
                  value={declaration.valuationInformationTaxes.deliveryTerms.incotermCode}
                  width={50}
                  compact
                  widthUnit="%"
                  disabled
                />
              </FormRow>
              <FormRow>
                <Input
                  name="valuationInformationTaxes.chargesimport.CustomsCharges.charges"
                  placeholder="Customs charges"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.valuationInformationTaxes.chargesimport.CustomsCharges.charges}
                  width={50}
                  compact
                  widthUnit="%"
                  disabled={disabled}
                />
                <Autocomplete
                  width={50}
                  widthUnit="%"
                  placeholder="Currency"
                  fetchOptions={(search: string) => retrieveCodes('CL048', search, declarant.language)}
                  onChange={(selectedOption) =>
                    form.setFieldValue(
                      'valuationInformationTaxes.chargesimport.CustomsCharges.currency',
                      selectedOption?.value,
                    )
                  }
                  value={{
                    value: form.values.valuationInformationTaxes.chargesimport.CustomsCharges.currency,
                    label: form.values.valuationInformationTaxes.chargesimport.CustomsCharges.currency,
                  }}
                  disabled={disabled}
                  compact
                />
              </FormRow>
              <FormRow>
                <Input
                  name="valuationInformationTaxes.chargesimport.VATcharges.charges"
                  placeholder="VAT charges"
                  onChange={(e) => {
                    form.setFieldValue(
                      'valuationInformationTaxes.chargesimport.VATcharges.charges',
                      e.target.value || undefined,
                    );
                  }}
                  onBlur={form.handleBlur}
                  value={form.values.valuationInformationTaxes.chargesimport.VATcharges?.charges}
                  width={50}
                  compact
                  widthUnit="%"
                  disabled={disabled}
                />
                <Autocomplete
                  width={50}
                  widthUnit="%"
                  placeholder="Currency"
                  fetchOptions={(search: string) => retrieveCodes('CL048', search, declarant.language)}
                  onChange={(selectedOption) =>
                    form.setFieldValue(
                      'valuationInformationTaxes.chargesimport.VATcharges.currency',
                      selectedOption?.value,
                    )
                  }
                  value={{
                    value: form.values.valuationInformationTaxes.chargesimport.VATcharges?.currency,
                    label: form.values.valuationInformationTaxes.chargesimport.VATcharges?.currency,
                  }}
                  disabled={disabled}
                  compact
                />
              </FormRow>
            </FormSection>
          </>
        )}
      </FormContainer>
    </>
  );
};
